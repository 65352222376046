<template>
  <v-container fluid>
    <TableViewComponent
      title="Table ticketing > objets / sous-objets"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un objet"
      itemLabel="objet"
      itemsLabel="objets"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cet objet ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    >
      <template v-slot:beforetable>
        <v-card-text>
          <div class="d-flex">
            <!-- Les applications -->
            <v-autocomplete
              v-model="selectedApp"
              :items="apps"
              item-text="label"
              return-object
              placeholder="Choisir une application"
              class="mx-4 my-0 pa-0"
              no-data-text="aucune application"
              :disabled="loading"
              @change="onSelectedAppChange()"
            >
            </v-autocomplete>

            <!-- Les services -->
            <v-autocomplete
              v-model="selectedService"
              :items="servicesOfApp"
              item-text="digitalName"
              return-object
              clearable
              no-data-text="aucun service"
              placeholder="Choisir un service"
              class="mx-4 my-0 pa-0"
              :disabled="loading"
              @change="onSelectedServiceChange()"
            >
            </v-autocomplete>
          </div>
        </v-card-text>
      </template>
      <template v-slot:aftertable v-if="canEdit">
        <v-card flat outlined class="my-4 py-4">
          <div class="d-flex align-center">
            <div class="ml-2 mr-10 mb-2 text-subtitle-1">
              Édition de la matrice des destinataires
            </div>
          </div>


          <v-row no-gutters>
            <v-col cols="7" class="ml-4">
              <div>1- Créez un nouvel objet en cliquant sur "Ajouter un objet"</div>
              <div>2- Renseignez les champs et ajoutez les sous-objets associés (cliquez bien sur le + pour enregistrer un sous-objet)</div>
              <div>3- Cliquez sur "Exporter la matrice"</div>
              <div>4- Cliquez sur "Ouvrir le Gsheet" et configurez les objets/sous-objets nouvellement créés</div>
              <div>5- Cliquez sur "Importer la matrice"</div>
            </v-col> 

            <v-col cols="4" class="justify-center">
              <!-- Bouton d'export de matrice -->
              <div class="d-flex align-center justify-center mr-6">
                <v-btn
                  class="ml-4 btn"
                  outlined
                  color="primary"
                  @click="exportMatrix"
                  :disabled="running"
                  :loading="running"
                >
                  Exporter la matrice
                </v-btn>
              </div>

              <!-- Bouton pour ouvrir le gsheet cible -->
              <div class="d-flex align-center justify-center mr-6 mt-4">
                <v-btn
                  class="ml-4 btn px-7"
                  outlined
                  color="primary"
                  :disabled="running"
                  :loading="running"
                  link
                  :href="urlGsheet"
                  target="_blank"
                >
                  Ouvrir le gsheet
                </v-btn>
              </div>

              <!-- Bouton d'import de matrice -->
              <div class="d-flex align-center justify-center mr-6 mt-4">
                <v-btn
                  class="ml-4 btn"
                  outlined
                  color="primary"
                  @click="showConfirmImport = true"
                  :disabled="running"
                  :loading="running"
                >
                  Importer la matrice
                </v-btn>
              </div>  
            </v-col>
          </v-row>

          <!-- Message de confirmation ou d'alerte -->
          <v-card-text v-if="failed">
            <v-alert type="error" border="left" text
              ><div style="opacity: 1">
                <span>{{ failure }}</span>
              </div>
              <div v-for="(e, i) in failureMessages" :key="i">
                <span>- {{ e }}</span>
              </div>
            </v-alert>
          </v-card-text>

          <v-card-text v-if="success">
            <v-alert type="info" border="left" text color="#E22F92"
              ><div style="opacity: 1">
                <span>{{ successMessage }}</span>
              </div>
            </v-alert>
          </v-card-text>

          <!-- Pour la confirmation de l'import -->
          <v-dialog
            v-model="showConfirmImport"
            :overlay="false"
            max-width="500px"
            transition="dialog-transition"
          >
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Importer la matrice
              </v-card-title>

              <v-card-text class="mt-4">
                Veuillez vérifier votre matrice dans le fichier gsheet avant de
                lancer l'import. Cet import écrasera les données dans la
                Database Globale.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="confirmImportMatrix">
                  LANCER L'IMPORT
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </template>
    </TableViewComponent>

    <!-- Alert désassociation statut spécifiques -->
    <StandardDialogConfirmed
    title="Confirmez votre action"
    label= "Vous allez aussi supprimer les statuts associé à l'application."
    :labelHtml="false"
    :visible.sync="showStandardDialog"
    :item="itemToDelete"
    @confirmed="deleteObject()" >
    </StandardDialogConfirmed>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";
import StandardDialogConfirmed from "@/components/ui/StandardDialogConfirmed.vue";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";
import { ApplicationService } from "@/service/dictionary/applications_service.js";
import ServicesService from "@/service/sfr/services_service.js";
import { TicketingStatusService } from "@/service/conf/ticketing_status_service.js";
import { TicketingService } from "@/service/backend/ticketing_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { roleCanEdit } from "@/service/role_service.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


// Le service qui n'en est pas un
// const _SERVICE_UNDEFINED = "undefined";
const _SERVICE_UNDEFINED = { id: "undefined", digitalName: "PAS DE SERVICE" };

export default {
  name: "TableTicketingObjectSubobject",
  components: { TableViewComponent, StandardDialogConfirmed, },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le service d'accès */
      service: null,
      serviceService: null,
      serviceApps: null,
      serviceTicketingStatus: null,

      /** l'application sélectonnée dans la combo */
      selectedApp: null,
      selectedAppInitial: null,
      /** La liste des apps pour le ticketing */
      apps: [],

      /** le service sélectionné dans la combo */
      selectedService: null,
      /** la liste des services de l'application */
      servicesOfApp: [],

      /** la liste des services du silo sfr */
      sourceServices: [],

      /** export/import en cours de traitement */
      running: false,

      /**échec export/import */
      failed: false,

      /**message échec export/import */
      failure: null,

      /**messages échec export/import */
      failureMessages: [],

      /**success export/import */
      success: false,

      /**message success export/import */
      successMessage: null,

      canEdit: false,

      /** url pour le gsheet d'import export */
      urlGsheet: null,

      /**afficher la popup de confirmation de l'import */
      showConfirmImport: false,

      /** l'objet à supprimer */
      itemToDelete: null,

      /** Affichage de la dialog de confirmation d'action */
      showStandardDialog: false,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.ticketingObjectSubobjects.add.path);
    },

    async load() {
      try {
        this.loading = true;

        this.apps = [];
        this.entities = [];
        this.selectedApp = null;
        this.selectedService = null;

        // Récupère la liste des applications dans les dico
        let allApps = await this.serviceApps.getAll();

        // Récupère la liste des services dans sfr
        this.sourceServices = await this.serviceService.getAllServices();
        this.sourceServices.push(_SERVICE_UNDEFINED);
        // Récupère la liste des apps ticket
        let appsConf = await this.service.getAllApps();

        // Récupère toutes les apps de la source qui matchent avec celles du ticket
        this.apps = allApps.filter((a) => appsConf.includes(a.id));

        // Récupère l'url du gsheet pour l'import export
        let urlgsheet = await this.serviceTicketing.getUrlGsheetTicketing();
        this.urlGsheet = urlgsheet.urlgsheet;
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Méthode de chargement de la liste des serices de l'app choisie */
    async loadService(appId) {
      try {
        this.loading = true;

        this.selectedService = null;
        // Récupère la liste des services de l'app
        let servicesApp = await this.service.getAllServicesFromApp(appId);

        this.servicesOfApp = [];
        // Récupération des objets service qui matchent avec la source
        this.servicesOfApp = this.sourceServices.filter((s) =>
          servicesApp.includes(s.id.toString())
        );

        await this.loadObjects();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des services: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Méthode de chargement des objects */
    async loadObjects() {
      try {
        this.loading = true;

        this.entities = [];
        let objects = [];
        // un service a été choisi on requête uniquement les objet du service
        if (this.selectedService) {
          let objs = await this.service.getAllObjectsOfService(
            this.selectedApp.id,
            this.selectedService.id
          );
          // Boucle de récupération des sous-objects pour chaque objets
          for (let j = 0; j < objs.length; j++) {
            objs[j].app = this.selectedApp;
            objs[j].service = this.selectedService;
            objs[j].subobjects = await this.loadSubObjects(
              this.selectedService.id,
              objs[j].id
            );
          }
          objects.push(...objs);
        } else {
          // aucun service choisi on requête chacuns des services
          for (let i = 0; i < this.servicesOfApp.length; i++) {
            let objs = await this.service.getAllObjectsOfService(
              this.selectedApp.id,
              this.servicesOfApp[i].id
            );

            // Boucle de récupération des sous-objects pour chaque objets
            for (let j = 0; j < objs.length; j++) {
              objs[j].app = this.selectedApp;
              objs[j].service = this.servicesOfApp[i];
              objs[j].subobjects = await this.loadSubObjects(
                this.servicesOfApp[i].id,
                objs[j].id
              );
            }

            objects.push(...objs);
          }
        }

        // Boucle de traitement des objets et assignation des actions
        for (let i = 0; i < objects.length; i++) {
          let entity = JSON.parse(JSON.stringify(objects[i]));

          // Récupération du nombre de sous-objets
          entity.nbSubobject = "aucun sous-objet";
          let nb = entity.subobjects.length;
          if (nb > 0) {
            if (nb > 1) {
              entity.nbSubobject = nb + " sous-objets";
            } else if (nb == 1) {
              entity.nbSubobject = "1 sous-objet";
            }
          }
          // action pour le détail
          entity.view = () => {
            this.$router.push(
              routes.ticketingObjectSubobjects.view.root +
                "/" +
                entity.id +
                "?app=" +
                entity.app.id +
                "&service=" +
                entity.service.id
            );
          };
          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.ticketingObjectSubobjects.edit.root +
                "/" +
                entity.id +
                "?app=" +
                entity.app.id +
                "&service=" +
                entity.service.id
            );
          };
          // action pour la suppression
          entity.delete = async () => {
            try {
              // Affectation de l'objet à supprimer
              this.itemToDelete = entity;

              if (this.entities.length == 1) {
                this.showStandardDialog = true;
              } else {
                this.deleteObject();
              }
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de l'objet : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        }

        // Tri du tableau par ordre alphabétique (au cas où il se serait perdu dans le foreach)
        // Un service est sélectionné, on tri sur les objet
        if (this.selectedService) {
          this.entities.sort(function (a, b) {
            return a.label.localeCompare(b.label);
          });
        } else {
          this.entities.sort(function (a, b) {
            return a.service.digitalName.localeCompare(b.service.digitalName);
          });
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des objets: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Méthode de récupération des sousObjects */
    async loadSubObjects(serviceId, objectId) {
      try {
        let sub = [];
        // Récupère tous les sous-objet d'un objet
        sub = await this.service.getAllSubobjects(
          this.selectedApp.id,
          serviceId,
          objectId
        );

        return sub;
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des sousObjets: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Traitement suppression de l'objet */
    async deleteObject() {
      // Suppression de l'objet.
      await this.service.deleteObject(this.itemToDelete.app.id, this.itemToDelete.service.id, this.itemToDelete.id);
      this.entities.splice(this.entities.findIndex((e) => this.itemToDelete.id == e.id), 1);

      // Plus d'objet dans la liste, on supprime les association app status
      if (this.entities.length == 0) {
        // Récupération de l'ensemble des status de l'app
        let statusOfApp = await this.service.getAllStatusOfApplication(this.itemToDelete.app.id);
        // Récupération de l'ensemble des status par défaut
        let defaultStatus = await this.serviceTicketingStatus.getAll();

        // L'application a-t-elle les status par défaut ?
        let appHasDefaultStatus = this.checkAppHasDefaultStatus(statusOfApp, defaultStatus);

        // Parcours de l'ensemble des status de l'app pour désassociation et suppressionsi statut spécifique
        for (let status of statusOfApp) {
          await this.service.disassociateStatusOfApp(this.itemToDelete.app.id, status.id);
        
          if (!appHasDefaultStatus) {
            await this.serviceTicketingStatus.delete(status.id);
          }
        }
      }

      this.load();
    },

    /** Analyse si l'application a les status par défaut ou non */
    checkAppHasDefaultStatus(statusApp, defaultStatus) {
      let statutDefault = true;

      // Parcours la liste des status de l'application 
      for (let status of statusApp) {
        // Tente de trouver le statut par défaut correspondant
        let found = defaultStatus.find((d) => d.id == status.id);

        if (!found) {
          statutDefault = false;
          break;
        }
      }

      return statutDefault;
    },

    /** Evènement sur le changement de la sélection de l'app */
    async onSelectedAppChange() {
      if (
        !this.selectedAppInitial ||
        this.selectedApp.id != this.selectedAppInitial.id
      ) {
        this.selectedAppInitial = this.selectedApp;
        this.loadService(this.selectedApp.id);
      }
    },

    /** Evènement sur le changement de la sélection d'un service */
    async onSelectedServiceChange() {
      await this.loadObjects();
    },

    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Application",
          align: "start",
          sortable: false,
          value: "app.label",
        },
        {
          text: "Service",
          align: "start",
          sortable: false,
          value: "service.digitalName",
        },
        {
          text: "Objet",
          align: "start",
          sortable: false,
          value: "label",
        },
        {
          text: "Sous-objet",
          align: "start",
          sortable: true,
          value: "nbSubobject",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },

    async exportMatrix() {
      try {
        this.failed = false;
        this.running = true;
        this.failure = null;
        this.failureMessages = [];
        this.success = false;
        this.successMessage = null;

        await this.serviceTicketing.exportTicketMatrix();

        this.success = true;
        this.successMessage = "La matrice est exportée.";
      } catch (error) {
        this.failed = true;
        this.failure =
          "La matrice n'a pas pu être exportée vers un gsheet. " +
          error.response.data.message;
      } finally {
        this.running = false;
      }
    },

    /**confirmation d'importation */
    confirmImportMatrix() {
      this.showConfirmImport = false;
      this.importMatrix();
    },

    async importMatrix() {
      try {
        this.failed = false;
        this.running = true;
        this.failure = null;
        this.failureMessages = [];

        this.success = false;
        this.successMessage = null;

        await this.serviceTicketing.importTicketMatrix();

        this.success = true;
        this.successMessage =
          "La matrice est importée dans la Database Globale.";
      } catch (error) {
        this.failed = true;
        this.failure =
          "La matrice n'a pas pu être importée vers la Database Globale. " +
          error.response.data.message +
          ".";
        this.failureMessages = error.response.data.report.messages;
      } finally {
        this.running = false;
      }
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditTicketingObjectSubObject];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditTicketingObjectSubObject, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [ RolesApplicationEnum.ReadTicketingObjectSubObject, ];
    },
  },
  mounted() {
    //on instancie les services
    this.service = new TicketingObjectSubobjectService(
      this.$api.getTicketingObjectSubobject()
    );
    this.serviceService = new ServicesService(this.$api);
    this.serviceApps = new ApplicationService(this.$api.getApplicationApi());
    this.serviceTicketing = new TicketingService(this.$api.getTicketingApi());
    this.serviceTicketingStatus = new TicketingStatusService(this.$api.getTicketingStatus());


    this.canEdit = roleCanEdit();

    this.load();
  },
};
</script>

<style>
</style>