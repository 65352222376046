var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("TableViewComponent", {
        attrs: {
          title: "Table ticketing > objets / sous-objets",
          addItemLabel: "ajouter un objet",
          itemLabel: "objet",
          itemsLabel: "objets",
          items: _vm.entities,
          columns: _vm.buildColumns(),
          deleteItemLabel: "Voulez-vous supprimer cet objet ?",
          loading: _vm.loading,
          rolesForEdition: _vm.rolesForEdition,
          rolesForAddDelete: _vm.rolesForAddDelete,
          rolesForRead: _vm.rolesForRead,
        },
        on: {
          addItemEvent: function ($event) {
            return _vm.onAddElement()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "beforetable",
              fn: function () {
                return [
                  _c("v-card-text", [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("v-autocomplete", {
                          staticClass: "mx-4 my-0 pa-0",
                          attrs: {
                            items: _vm.apps,
                            "item-text": "label",
                            "return-object": "",
                            placeholder: "Choisir une application",
                            "no-data-text": "aucune application",
                            disabled: _vm.loading,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSelectedAppChange()
                            },
                          },
                          model: {
                            value: _vm.selectedApp,
                            callback: function ($$v) {
                              _vm.selectedApp = $$v
                            },
                            expression: "selectedApp",
                          },
                        }),
                        _c("v-autocomplete", {
                          staticClass: "mx-4 my-0 pa-0",
                          attrs: {
                            items: _vm.servicesOfApp,
                            "item-text": "digitalName",
                            "return-object": "",
                            clearable: "",
                            "no-data-text": "aucun service",
                            placeholder: "Choisir un service",
                            disabled: _vm.loading,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSelectedServiceChange()
                            },
                          },
                          model: {
                            value: _vm.selectedService,
                            callback: function ($$v) {
                              _vm.selectedService = $$v
                            },
                            expression: "selectedService",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            _vm.canEdit
              ? {
                  key: "aftertable",
                  fn: function () {
                    return [
                      _c(
                        "v-card",
                        {
                          staticClass: "my-4 py-4",
                          attrs: { flat: "", outlined: "" },
                        },
                        [
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ml-2 mr-10 mb-2 text-subtitle-1",
                              },
                              [
                                _vm._v(
                                  " Édition de la matrice des destinataires "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "ml-4", attrs: { cols: "7" } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      '1- Créez un nouvel objet en cliquant sur "Ajouter un objet"'
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "2- Renseignez les champs et ajoutez les sous-objets associés (cliquez bien sur le + pour enregistrer un sous-objet)"
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      '3- Cliquez sur "Exporter la matrice"'
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      '4- Cliquez sur "Ouvrir le Gsheet" et configurez les objets/sous-objets nouvellement créés'
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      '5- Cliquez sur "Importer la matrice"'
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "justify-center",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center mr-6",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-4 btn",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                            disabled: _vm.running,
                                            loading: _vm.running,
                                          },
                                          on: { click: _vm.exportMatrix },
                                        },
                                        [_vm._v(" Exporter la matrice ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center mr-6 mt-4",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-4 btn px-7",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                            disabled: _vm.running,
                                            loading: _vm.running,
                                            link: "",
                                            href: _vm.urlGsheet,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(" Ouvrir le gsheet ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center mr-6 mt-4",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-4 btn",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                            disabled: _vm.running,
                                            loading: _vm.running,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showConfirmImport = true
                                            },
                                          },
                                        },
                                        [_vm._v(" Importer la matrice ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.failed
                            ? _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        type: "error",
                                        border: "left",
                                        text: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { opacity: "1" } },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.failure)),
                                          ]),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.failureMessages,
                                        function (e, i) {
                                          return _c("div", { key: i }, [
                                            _c("span", [
                                              _vm._v("- " + _vm._s(e)),
                                            ]),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.success
                            ? _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        type: "info",
                                        border: "left",
                                        text: "",
                                        color: "#E22F92",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { opacity: "1" } },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.successMessage)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-dialog",
                            {
                              attrs: {
                                overlay: false,
                                "max-width": "500px",
                                transition: "dialog-transition",
                              },
                              model: {
                                value: _vm.showConfirmImport,
                                callback: function ($$v) {
                                  _vm.showConfirmImport = $$v
                                },
                                expression: "showConfirmImport",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "headline grey lighten-2" },
                                    [_vm._v(" Importer la matrice ")]
                                  ),
                                  _c("v-card-text", { staticClass: "mt-4" }, [
                                    _vm._v(
                                      " Veuillez vérifier votre matrice dans le fichier gsheet avant de lancer l'import. Cet import écrasera les données dans la Database Globale. "
                                    ),
                                  ]),
                                  _c("v-divider"),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", text: "" },
                                          on: {
                                            click: _vm.confirmImportMatrix,
                                          },
                                        },
                                        [_vm._v(" LANCER L'IMPORT ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("StandardDialogConfirmed", {
        attrs: {
          title: "Confirmez votre action",
          label:
            "Vous allez aussi supprimer les statuts associé à l'application.",
          labelHtml: false,
          visible: _vm.showStandardDialog,
          item: _vm.itemToDelete,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showStandardDialog = $event
          },
          confirmed: function ($event) {
            return _vm.deleteObject()
          },
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }